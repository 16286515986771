import React, { useRef, useState } from 'react';

import "./Slider.scss";

export const sliderOrientations = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL'
};

const getCSSClassName = (orientation, current) => {
  const baseCSSClassName = 'Slider';
  let className;

  if (orientation === sliderOrientations.HORIZONTAL) {
    className = `${baseCSSClassName} ${baseCSSClassName}--horizontal`
  }

  if (orientation === sliderOrientations.VERTICAL) {
    className = `${baseCSSClassName} ${baseCSSClassName}--vertical`
  }

  if (current > 0) {
    className = `${className} ${baseCSSClassName}--not-zero`;
  }

  return className;
};

const getFillRule = (current, maximum, orientation) => {
  const amount = (current / maximum) * 100;

  if (orientation === sliderOrientations.HORIZONTAL) {
    return { width: amount + "%" };
  }

  if (orientation === sliderOrientations.VERTICAL) {
    return { height: amount + "%" }
  }
};

function Slider(props) {
  const { current, maximum, updatePositionCB, orientation } = props;
  const [ isTrackingMouseMovement, setIsTrackingMouseMovement ] = useState(false);

  const rootElement = useRef(null);
  const sliderTrackElement = useRef(null);

  const handleSliderInteraction = (e) => {
    const elementWidth = sliderTrackElement.current.offsetWidth;
    const elementHeight = sliderTrackElement.current.offsetHeight;

    const elementOffsetLeft = sliderTrackElement.current.getBoundingClientRect().left;
    const elementOffsetTop = sliderTrackElement.current.getBoundingClientRect().top;

    const windowClickPositionHorizontal = e.clientX;
    const windowClickPositionVertical = e.clientY;

    const elementClickPositionHorizontal = windowClickPositionHorizontal - elementOffsetLeft;
    const elementClickPositionVertical = windowClickPositionVertical - elementOffsetTop;

    const elementClickPercentWidth = elementClickPositionHorizontal / elementWidth;
    const elementClickPercentHeight = elementClickPositionVertical / elementHeight;

    let targetPosition = 0;

    if (orientation === sliderOrientations.HORIZONTAL) {
      targetPosition = maximum * elementClickPercentWidth;
    }

    if (orientation === sliderOrientations.VERTICAL) {
      const invertedPercent = elementClickPercentHeight > 1 ? 0 : Math.abs(elementClickPercentHeight - 1);
      targetPosition = maximum * invertedPercent;
    }

    if (targetPosition > maximum) {
      targetPosition = maximum;
    }
    else if (targetPosition < 0) {
      targetPosition = 0;
    }

    updatePositionCB(targetPosition);
  };

  /* Event Handlers */
  const handleMouseDown = (e) => {
    setIsTrackingMouseMovement(true);
    handleSliderInteraction(e);
  };

  const handleMouseUp = (e) => {
    setIsTrackingMouseMovement(false);
    handleSliderInteraction(e);
  };

  const handleMouseLeave = (e) => {
    setIsTrackingMouseMovement(false);
  };

  const handleMouseMove = (e) => {
    if (isTrackingMouseMovement) {
      handleSliderInteraction(e);
    }
  };

  return (
    <div ref={rootElement} className={getCSSClassName(orientation, current)} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      <div ref={sliderTrackElement} className="Slider_track">
        <div style={getFillRule(current, maximum, orientation)} className="Slider_track_fill"></div>
      </div>
    </div>
  );
};

export default Slider;

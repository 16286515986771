import React from 'react';

import "./Divider.scss";

function Divider() {

  return (
    <div className="Divider"></div>
  );
};

export default Divider;
import React from 'react';

import './Time.scss';

const getFormattedTime = (time) => {
  let sec_num = time;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  seconds = Math.floor(seconds);

  if (hours < 10) {hours = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}

  return minutes+':'+seconds;
};

const getCurrentTimeClassName = (currentTime) => {
  const baseClassName = 'Time_current-time';
  return currentTime > 0 ? `${baseClassName} ${baseClassName}--not-zero` : baseClassName;
};

function Time(props) {
  const { currentTime, duration } = props;

  return (
    <div className="Time">
      <span className={getCurrentTimeClassName(currentTime)}>{ getFormattedTime(currentTime) }</span> / <span className="Time_duration">{ getFormattedTime(duration) }</span>
    </div>
  );
};

export default Time;

import ReactGA from 'react-ga';

const doIfProduction = (func) => {
  if (process.env.NODE_ENV === 'production') {
    func();
  }
};

export const initializeAnalytics = () => {
  doIfProduction(() => { ReactGA.initialize('UA-157808063-1'); });
};

export const getWindowPath = () => {
  return window.location.pathname + window.location.search;
};

/* Page View */
export const reportPageView = () => {
  doIfProduction(() => { ReactGA.pageview(getWindowPath()); });
};

/* Events */
export const reportAudioPlay = (trackName, nonInteraction) => {
  doIfProduction(() => {
    ReactGA.event({
      category: 'Audio',
      action: 'Play',
      label: trackName,
      nonInteraction: nonInteraction
    });
  });
};

export const reportAudioPause = (trackName) => {
  doIfProduction(() => {
    ReactGA.event({
      category: 'Audio',
      action: 'Pause',
      label: trackName
    });
  });
};

export const reportAudioEnd = (trackName) => {
  doIfProduction(() => {
    ReactGA.event({
      category: 'Audio',
      action: 'End',
      label: trackName,
      nonInteraction: true
    });
  });
};

export const reportAudioDownloadMP3320kbps = (trackName) => {
  doIfProduction(() => {
    ReactGA.event({
      category: 'Audio',
      action: 'Download MP3 320 kbps',
      label: trackName
    });
  });
};

export const reportAudioDownloadFLAC16Bit = (trackName) => {
  doIfProduction(() => {
    ReactGA.event({
      category: 'Audio',
      action: 'Download FLAC 16 bit',
      label: trackName
    });
  });
};

import { ABOUT_SET_SOFTWARE } from '../actionTypes';

const initialState = {
  software: []
};

export default function about(state = initialState, action) {

  switch(action.type) {
    /* About Set Software */
    case ABOUT_SET_SOFTWARE: {
      return {
        ...state,
        software: action.payload
      }
    }

    /* Default */
    default: {
      return state;
    }
  }
};

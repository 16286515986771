const makeTracks = (tracksArray) => {
  let tracks = {};

  tracksArray.forEach((track) => {
    tracks[track.name] = track;
  });

  return tracks;
};

export default makeTracks;

import React from 'react';

import './Footer.scss';

function Footer() {

  return (
    <div className="Footer">
      <div className="Footer_feeds">
        <div className="Footer_feeds-title">Feeds:</div>
        <ul className="Footer_feeds-list">
          <li className="Footer_feeds-list-item">
            <a href="/feed.rss" className="Footer_feeds-list-link">RSS</a>
          </li>
          <li className="Footer_feeds-list-item">
            <a href="/feed.atom" className="Footer_feeds-list-link">ATOM</a>
          </li>
          <li className="Footer_feeds-list-item">
            <a href="/feed.json" className="Footer_feeds-list-link">JSON</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useTitle from '../../hooks/useTitle';
import PageContainer, { pageContainerLayouts } from '../PageContainer';
import usePageView from '../../hooks/usePageView';
import TrackDetails from '../TrackDetails';
import { useSelector } from 'react-redux';
import { getPlaylist, getTracks } from '../../redux/selectors';
import { paths } from '../../constants';

function TrackPage() {
  const { trackName } = useParams();

  const tracks = useSelector(getTracks);
  const track = tracks[trackName];
  const playlist = useSelector(getPlaylist);

  useTitle(`Track ${trackName}`);

  usePageView();

  let content;

  if (playlist.length === 0) {
    content = '';
  }
  else if (track == null) {
    content = <p>Track not found. <Link to={ paths.home }>Return to track list.</Link></p>
  }
  else {
    content = (<TrackDetails track={track}></TrackDetails>);
  }

  return (
    <div className="TrackPage">
      <div className="container">
        <PageContainer layout={ pageContainerLayouts.TRACK }>
          { content }
        </PageContainer>
      </div>
    </div>
  );
};

export default TrackPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../constants';

import './Header.scss';

function Header() {

  return (
    <div className="Header">
      <div className="container">
        <h1 className="Header_h1">
          <Link to={paths.home} className="Header_Link">Zyrisk</Link>
        </h1>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';

import './BackgroundStone.scss';

export default function BackgroundStone() {

  return (
    <div className="BackgroundStone"></div>
  )
};

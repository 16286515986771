import { useEffect } from 'react';
import { reportPageView, getWindowPath } from '../helpers/googleAnalytics';

let prevPath = '';

const usePageView = () => {

  useEffect(() => {
    const newPath = getWindowPath();
    if (newPath !== prevPath) {
      reportPageView();
      prevPath = newPath;
    }
  });
};

export default usePageView;

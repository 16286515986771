import React from 'react';
import Track from './Track';
import { useSelector } from 'react-redux';
import { getPlaylist } from '../redux/selectors';

import './Playlist.scss';


function Playlist() {

  const playlist = useSelector(getPlaylist);

  return (
    <div className="Playlist">
      <ul className="Playlist_list">
        { playlist.map((track, index) => { return (<li className="Playlist_list-item" key={index}><Track track={track} ></Track></li>); }) }
      </ul>
    </div>
  );
};

export default Playlist;

import { TRACK_PLAY, TRACK_PAUSE, TRACK_SEEK, TRACK_SELECT, PLAYER_SET_TRACKS, PLAYER_PLAY, PLAYER_PAUSE, PLAYER_STEP_BACKWARD, PLAYER_STEP_FORWARD, PLAYER_SEEK, PLAYER_SET_VOLUME, AUDIO_TIME_UPDATE, AUDIO_END, PLAYER_MUTE, PLAYER_UNMUTE, ABOUT_SET_SOFTWARE } from "./actionTypes";
import { reportAudioPlay, reportAudioPause, reportAudioEnd } from "../helpers/googleAnalytics";
import { getNextTrackIndex, getPreviousTrackIndex } from "../helpers/trackIndexHelpers";
import { apiFetchTracks } from "../api/tacks";
import { apiFetchAboutSoftware } from "../api/aboutSoftware";

/*  Track Action Creators */
export const trackPlay = (track) => {
  return (dispatch, getState) => {

    /* Analytics */
    const state = getState();
    if (state.player.track === track) {

      if (state.player.currentTime === 0) {
        reportAudioPlay(track.name, false);
      }
    }
    else {
      reportAudioPlay(track.name, false);
    }

    dispatch({
      type: TRACK_PLAY,
      payload: { track }
    });
  };
};

export const trackPause = () => {
  return (dispatch, getState) => {

    /* Analytics */
    reportAudioPause(getState().player.track.name);

    dispatch({
      type: TRACK_PAUSE
    });
  };
};

export const trackSeek = (track, time) => ({
  type: TRACK_SEEK,
  payload: { track, time }
});

export const trackSelect = (track) => {
  return (dispatch) => {

    /* Analytics */
    reportAudioPlay(track.name, false);

    dispatch({
      type: TRACK_SELECT,
      payload: { track }
    });
  };
};

/* Player Action Creators */
export const playerSetTracks = (tracks) => {
  return {
    type: PLAYER_SET_TRACKS,
    payload: tracks
  };
};

export const playerFetchTracks = () => {
  return (dispatch) => {
    apiFetchTracks()
    .then((res) => {
      dispatch(playerSetTracks(res.data));
    })
    .catch((error) => {
      console.log('Failed to fetch tracks.', error);
    });
  };
};

export const playerPlay = () => {
  return (dispatch, getState) => {

    /* Analytics */
    const state = getState();
    if (state.player.currentTime === 0) {
      reportAudioPlay(state.player.track.name, false);
    }

    dispatch({
      type: PLAYER_PLAY
    });
  };
};

export const playerPause = () => {
  return (dispatch, getState) => {

    /* Analytics */
    reportAudioPause(getState().player.track.name, false);

    dispatch({
        type: PLAYER_PAUSE
    });
  };
};

export const playerStepBackward = () => {
  return (dispatch, getState) => {

    /* Analytics */
    const state = getState();
    if (state.player.currentTime <= 5) {
      const targetTrack = state.player.playlist[getPreviousTrackIndex(state.player.playlist, state.player.track)];

      if (state.player.isPlaying) {
        reportAudioPlay(targetTrack.name, false);
      }
    }

    dispatch({
      type: PLAYER_STEP_BACKWARD
    });
  };
};

export const playerStepForward = () => {
  return (dispatch, getState) => {

    /* Analytics */
    const state = getState();
    if (state.player.isPlaying) {
      const targetTrack = state.player.playlist[getNextTrackIndex(state.player.playlist, state.player.track)];
      reportAudioPlay(targetTrack.name, false);
    }

    dispatch({
      type: PLAYER_STEP_FORWARD
    });
  };
};

export const playerSeek = (time) => ({
  type: PLAYER_SEEK,
  payload: { time }
});

export const playerSetVolume = (volume) => ({
  type: PLAYER_SET_VOLUME,
  payload: { volume }
});

export const playerMute = () => ({
  type: PLAYER_MUTE
});

export const playerUnmute = () => ({
  type: PLAYER_UNMUTE
});

/* Audio Action Creators */
export const audioTimeUpdate = (time) => ({
  type: AUDIO_TIME_UPDATE,
  payload: { time }
});

export const audioEnd = () => {
  return (dispatch, getState) => {

    /* Analytics */
    const state = getState();
    reportAudioEnd(state.player.track.name);

    const nextTrackIndex = getNextTrackIndex(state.player.playlist, state.player.track);
    const nextTrack = state.player.playlist[nextTrackIndex];

    if (nextTrackIndex !== 0) {
      reportAudioPlay(nextTrack.name, true);
    }

    dispatch({
      type: AUDIO_END
    });
  };
};

/* About Action Creators */
export const aboutSetSoftware = (software) => {
  return {
    type: ABOUT_SET_SOFTWARE,
    payload: software
  };
};

export const aboutFetchSoftware = () => {
  return (dispatch) => {
    apiFetchAboutSoftware()
    .then((res) => {
      dispatch(aboutSetSoftware(res.data));
    })
    .catch((error) => {
      console.log('Failed to fetch about software.', error);
    });
  }
};

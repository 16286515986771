import React from 'react';
import { NavLink } from "react-router-dom";
import { paths } from '../constants';

import './Nav.scss';

function Nav(props) {

  return (
    <div className="Nav">
      <div className="container">
        <nav className="Nav_nav">
          <ul className="Nav_list">
            <li className="Nav_list-item">
              <NavLink exact to={paths.home} className="Nav_NavLink" activeClassName="Nav_NavLink--selected">Tracks</NavLink>
            </li>
            <li className="Nav_list-item">
              <NavLink exact to={paths.about} className="Nav_NavLink" activeClassName="Nav_NavLink--selected">About</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Nav;

import React from 'react';

import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { paths } from './constants';
import { initializeAnalytics } from './helpers/googleAnalytics';

import Header from './components/Header';
import Nav from './components/Nav';
import HomePage from './components/pages/HomePage';
import AboutPage from './components/pages/AboutPage';
import TrackPage from './components/pages/TrackPage';
import Footer from './components/Footer';
import Player from './components/Player';
import BackgroundStone from './components/BackgroundStone';

import './App.scss';

initializeAnalytics();

function App() {

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Header></Header>
          <Nav></Nav>

          <main className="App_main">
            <Switch>
              <Route exact path={paths.home}>
                <HomePage></HomePage>
              </Route>
              <Route path={paths.about}>
                <AboutPage></AboutPage>
              </Route>
              <Route path={`${paths.tracks}/:trackName`}>
                <TrackPage></TrackPage>
              </Route>
            </Switch>
          </main>

          <Footer></Footer>

          <Player></Player>

          <BackgroundStone></BackgroundStone>
        </Router>
      </Provider>
    </div>
  );
}

export default App;

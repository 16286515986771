/* Player Selectors */
export const getIsPlaying = store => store.player.isPlaying;
export const getCurrentTime = store => store.player.currentTime;
export const getPlaylist = store => store.player.playlist;
export const getTracks = store => store.player.tracks;
export const getTrack = store => store.player.track;
export const getVolume = store => store.player.volume;
export const getMuted = store => store.player.muted;

/* About Selectors */
export const getAboutSoftware = store => store.about.software;

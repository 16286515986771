const makeTrackUrl = (trackName) => {
  // const baseUrl = 'http://zyriskmusic.s3-website-us-west-1.amazonaws.com/';
  // const baseUrl = process.env.PUBLIC_URL + '/tracks/';
  const baseUrl = 'https://storage.googleapis.com/zyriskmusictracks/';

  return {
    stream: baseUrl + trackName + '.mp3',
    mp3: baseUrl + trackName + '_320kbps.mp3',
    flac: baseUrl + trackName + '.flac',
  };
};

export default makeTrackUrl;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import Divider from './Divider';
import Track from './Track';
import { reportAudioDownloadMP3320kbps, reportAudioDownloadFLAC16Bit } from '../helpers/googleAnalytics';
import makeTrackUrl from '../helpers/makeTrackUrl';

import "./TrackDetails.scss";
import downloadIcon from '../img/icons/icon-download-brand.svg';

function TrackDetailsTitle({ track }) {

  return (
    <div className="TrackDetailsTitle">
      <h1 className="TrackDetailsTitle-name">{ track.name }</h1>
      <ul className="TrackDetailsTitle_downloads-list">
        <li className="TrackDetailsTitle_downloads-list-item">
          <a
            className="TrackDetailsTitle_download-link"
            onClick={ () => {reportAudioDownloadMP3320kbps(track.name)} }
            download
            href={ makeTrackUrl(track.name).mp3 }>
              <img src={ downloadIcon } alt="Download Icon" /> mp3 (320 kbps)
          </a>
        </li>
        <li className="TrackDetailsTitle_downloads-list-item">
          <a
            className="TrackDetailsTitle_download-link"
            onClick={ () => {reportAudioDownloadFLAC16Bit(track.name)} }
            download
            href={ makeTrackUrl(track.name).flac }>
              <img src={ downloadIcon } alt="Download Icon" /> flac (16 bit)
          </a>
        </li>
      </ul>
    </div>
  );
};

function TrackDetailsLoudness({ track }) {

  const makeLoudnessStats = (loudness) => {
    if (loudness !== undefined) {
      return (
        <ul className="TrackDetailsLoudness_list">
          { loudness.map((loudness, index) => { return (<li key={ index } className="TrackDetailsLoudness_list-item"><LoudnessStatistic name={ loudness.name } value={ loudness.value } unit={ loudness.unit }></LoudnessStatistic></li>)}) }
        </ul>
      );
    }
    else {
      return (<div className="TrackDetailsLoudness_no-data"><p><em>No loudness data.</em></p></div>);
    }
  }

  return (
    <div className="TrackDetailsLoudness">
      { makeLoudnessStats(track.loudness) }
    </div>
  );
};

function LoudnessStatistic({ name, value, unit }) {

  return (
    <div className="LoudnessStatistic">
      <div className="LoudnessStatistic_name">{ name }</div>
      <div className="LoudnessStatistic_value">{ value } { unit }</div>
    </div>
  );
};

function TrackDetailsDescription({ track }) {

  const getDescription = (track) => {
    if (track.description !== undefined) {
      return (
        <div className="TrackDetailsDescription_description">
          <ReactMarkdown children={ track.description } />
        </div>
      );
    }
    else {
      return (<p><em>No description given.</em></p>);
    }
  };

  const getSoftware = (track) => {
    if (track.software !== undefined) {

      return (
        <div>
          <div className="TrackDetailsDescription_software-header"><strong><em>Software</em></strong></div>
          <ul className="TrackDetailsDescription_software-list">
            { track.software.map((software, index) => { return (<li key={ index } className="TrackDetailsDescription_software-list-item"><a href={ software.url } target="_blank" rel="noopener noreferrer">{ software.name }</a></li>)}) }
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="TrackDetailsDescription">
      { getDescription(track) }
      { getSoftware(track) }
    </div>
  );
};

function TrackDetails({ track }) {

  return (
    <div className="TrackDetails">

      <div className="TrackDetails_section">
        <Track track={track}></Track>
      </div>

      <div className="TrackDetails_section">
        <TrackDetailsTitle track={track}></TrackDetailsTitle>
      </div>

      <Divider></Divider>

      <div className="TrackDetails_section">
        <h2 className="TrackDetails_section-header">Description</h2>
        <TrackDetailsDescription track={track}></TrackDetailsDescription>
      </div>

      <Divider></Divider>

      <div className="TrackDetails_section">
        <h2 className="TrackDetails_section-header">Loudness</h2>
        <TrackDetailsLoudness track={track}></TrackDetailsLoudness>
      </div>
    </div>
  );
};

export default TrackDetails;

import React from 'react';
import Playlist from '../Playlist';
import useTitle from '../../hooks/useTitle';
import PageContainer, { pageContainerLayouts } from '../PageContainer';
import usePageView from '../../hooks/usePageView';

function HomePage() {
  useTitle('Tracks');
  usePageView();

  return (
    <div className="HomePage">
      <div className="container">
        <PageContainer layout={ pageContainerLayouts.TRACKS }>
          <Playlist></Playlist>
        </PageContainer>
      </div>
    </div>
  );
};

export default HomePage;

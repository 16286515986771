import { useEffect } from 'react';

const titleAppend = 'Zyrisk Music';

const useTitle = (title) => {

  useEffect(() => {
    document.title = `${title} - ${titleAppend}`;
  });
};

export default useTitle;

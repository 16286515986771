/* Track Actions */
export const TRACK_PLAY = 'TRACK_PLAY';
export const TRACK_PAUSE = 'TRACK_PAUSE';
export const TRACK_SEEK = 'TRACK_SEEK';
export const TRACK_SELECT = 'TRACK_SELECT';

/* Player Actions */
export const PLAYER_SET_TRACKS = 'PLAYER_SET_TRACKS';
export const PLAYER_PLAY = 'PLAYER_PLAY';
export const PLAYER_PAUSE = 'PLAYER_PAUSE';
export const PLAYER_STEP_BACKWARD = 'PLAYER_STEP_BACKWARD';
export const PLAYER_STEP_FORWARD = 'PLAYER_STEP_FORWARD';
export const PLAYER_SEEK = 'PLAYER_SEEK';
export const PLAYER_SET_VOLUME = 'PLAYER_SET_VOLUME';
export const PLAYER_MUTE = 'PLAYER_MUTE';
export const PLAYER_UNMUTE = 'PLAYER_UNMUTE';

/* Audio Actions */
export const AUDIO_TIME_UPDATE = 'AUDIO_TIME_UPDATE';
export const AUDIO_END = 'AUDIO_END';

/* About Actions */
export const ABOUT_SET_SOFTWARE = 'ABOUT_SET_SOFTWARE';

const getTrackIndex = (playlist, targetTrack) => {
  let trackIndex;

  playlist.forEach((track, index) => {
    if (targetTrack === track) {
      trackIndex = index;
    }
  });

  return trackIndex;
};

export const getNextTrackIndex = (playlist, currentTrack) => {
  const nextTrackIndex = getTrackIndex(playlist, currentTrack) + 1;

  return nextTrackIndex > playlist.length - 1 ? 0 : nextTrackIndex;
};

export const getPreviousTrackIndex = (playlist, currentTrack) => {
  let previousTrackIndex = getTrackIndex(playlist, currentTrack) - 1;

  return previousTrackIndex < 0 ? playlist.length - 1 : previousTrackIndex;
};

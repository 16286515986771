import { TRACK_PLAY, AUDIO_TIME_UPDATE, TRACK_PAUSE, TRACK_SEEK, TRACK_SELECT, PLAYER_PLAY, PLAYER_PAUSE, PLAYER_STEP_BACKWARD, PLAYER_STEP_FORWARD, PLAYER_SEEK, PLAYER_SET_VOLUME, AUDIO_END, PLAYER_MUTE, PLAYER_UNMUTE, PLAYER_SET_TRACKS } from "../actionTypes";
import { getNextTrackIndex, getPreviousTrackIndex } from "../../helpers/trackIndexHelpers";
import makeTracks from "../../helpers/makeTracks";

const initialState = {
  isPlaying: false,
  currentTime: 0,
  playlist: [],
  tracks: {},
  track: {},
  volume: 0.75,
  muted: false
};

export default function player(state = initialState, action) {
  switch(action.type) {
    /* Track Action Types */
    case TRACK_PLAY: {

      if (state.track === action.payload.track) {

        return {
          ...state,
          isPlaying: true
        };
      }
      else {

        return {
          ...state,
          track: action.payload.track,
          currentTime: 0,
          isPlaying: true
        };
      }
    }
    case TRACK_PAUSE: {

      return {
        ...state,
        isPlaying: false
      };
    }
    case TRACK_SEEK: {
      return {
        ...state,
        track: action.payload.track,
        currentTime: action.payload.time
      };
    }
    case TRACK_SELECT: {

      return {
        ...state,
        track: action.payload.track,
        currentTime: 0,
        isPlaying: true
      };
    }

    /* Player Action Types */
    case PLAYER_SET_TRACKS: {
      return {
        ...state,
        playlist: action.payload,
        tracks: makeTracks(action.payload),
        track: action.payload[0]
      }
    }
    case PLAYER_PLAY: {

      if (!state.track.name) {
        return {
          ...state
        }
      }

      return {
        ...state,
        isPlaying: true
      };
    }
    case PLAYER_PAUSE: {

      if (!state.track.name) {
        return {
          ...state
        }
      }

      return {
        ...state,
        isPlaying: false
      };
    }
    case PLAYER_STEP_BACKWARD: {

      if (!state.track.name) {
        return {
          ...state
        }
      }

      if (state.currentTime > 5) {
        return {
          ...state,
          currentTime: 0
        }
      }
      else {
        const targetTrack = state.playlist[getPreviousTrackIndex(state.playlist, state.track)];

        return {
          ...state,
          currentTime: 0,
          track: targetTrack
        }
      }
    }
    case PLAYER_STEP_FORWARD: {

      if (!state.track.name) {
        return {
          ...state
        }
      }

      const targetTrack = state.playlist[getNextTrackIndex(state.playlist, state.track)];

      return {
        ...state,
        currentTime: 0,
        track: targetTrack
      }
    }
    case PLAYER_SEEK: {

      if (!state.track.name) {
        return {
          ...state
        }
      }

      return {
        ...state,
        currentTime: action.payload.time
      };
    }
    case PLAYER_SET_VOLUME: {
      return {
        ...state,
        volume: action.payload.volume,
        muted: false
      };
    }
    case PLAYER_MUTE: {
      return {
        ...state,
        muted: true
      }
    }
    case PLAYER_UNMUTE: {
      return {
        ...state,
        muted: false
      }
    }

    /* Audio Action Types */
    case AUDIO_TIME_UPDATE: {
      return {
        ...state,
        currentTime: action.payload.time
      };
    }
    case AUDIO_END: {
      const nextTrackIndex = getNextTrackIndex(state.playlist, state.track);
      const nextTrack = state.playlist[nextTrackIndex];

      if (nextTrackIndex === 0) {
        return {
          ...state,
          currentTime: 0,
          isPlaying: false
        }
      }
      else {
        return {
          ...state,
          currentTime: 0,
          track: nextTrack
        }
      }
    }

    /* Default */
    default: {
      return state;
    }
  }
};

import axiosConfigured from './axiosConfigured';

export const apiFetchTracks = () => {
  return axiosConfigured.get('/tracks');
};

export const apiFetchTrack = (trackName) => {
  return axiosConfigured.get('/track', {
    trackName: trackName
  });
};

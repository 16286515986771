import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTrack, getCurrentTime, getIsPlaying } from '../redux/selectors';
import Slider, { sliderOrientations } from './Slider';
import Time from './Time';
import { Link } from 'react-router-dom';
import TrackTags from './TrackTags';
import { trackPause, trackPlay, trackSeek, trackSelect } from '../redux/actions';
import { paths } from '../constants';

import './Track.scss';
import playIcon from '../img/controls/play-white.svg';
import pauseIcon from '../img/controls/pause-white.svg';

const getSliderMask = (track, currentTrack, dispatch) => {
  if (track !== currentTrack) {
    return (<div className="Track_slider-mask" onClick={ () => { dispatch(trackSelect(track)) } }></div>);
  }
};

const handleSliderInteraction = (targetPosition, track, currentTrack, dispatch) => {

  if (currentTrack === track) {
    dispatch(trackSeek(track, targetPosition));
  }
  else {
    dispatch(trackSelect(track));
  }
};

const getPlayPauseButton = (isPlaying, track, currentTrack, dispatch) => {
  let callback;
  let text;

  if (isPlaying && track === currentTrack) {
    text = (<img src={pauseIcon} alt="Pause Icon" />);
    callback = () => { dispatch(trackPause()); };
  }
  else {
    text = (<img src={playIcon} alt="Play Icon" />);
    callback = () => { dispatch(trackPlay(track)); };
  }

  return (<button className="Track_play-pause-button" onClick={callback}>{text}</button>);
};

const getCSSClassName = (track, currentTrack) => {
  const baseCSSClassName = 'Track';
  const currentCSSClassName = `${baseCSSClassName} ${baseCSSClassName}--current`;

  return track === currentTrack ? currentCSSClassName : baseCSSClassName;
};

function Track(props) {
  const { track } = props;
  const currentTrack = useSelector(getTrack);
  const currentTime = useSelector(getCurrentTime);
  const isPlaying = useSelector(getIsPlaying);
  const dispatch = useDispatch();

  return (
    <div className={getCSSClassName(track, currentTrack)}>
      <div className="Track_item Track_item--play-pause-button">
        { getPlayPauseButton(isPlaying, track, currentTrack, dispatch) }
      </div>
      <div className="Track_item Track_item--info">
        <div className="Track_header">
          <div className="Track_header-item Track_header-item--title">
            <Link className="Track_title" to={`${paths.tracks}/${track.name}`}>{track.name}</Link>
          </div>
          <div className="Track_header-item Track_header-item--tags">
            <TrackTags tags={track.tags}></TrackTags>
          </div>
          <div className="Track_header-item Track_header-item--time">
            <Time currentTime={ track === currentTrack ? currentTime : 0 } duration={track.duration}></Time>
          </div>
        </div>
        <div className="Track_slider">
          { getSliderMask(track, currentTrack, dispatch) }
          <Slider current={ track === currentTrack ? currentTime : 0 } maximum={track.duration} updatePositionCB={ (targetPosition) => { handleSliderInteraction(targetPosition, track, currentTrack, dispatch); } } orientation={sliderOrientations.HORIZONTAL}></Slider>
        </div>
      </div>
    </div>
  );
};

export default Track;

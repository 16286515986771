import React from 'react';

import './TrackTags.scss'

const getTrackListItemClassName = (tag) => {
  return `TrackTags_list-item TrackTags_list-item--${tag}`;
};

export default function TrackTags(props) {

  const { tags } = props;

  return (
    <div className="TrackTags">
      <ul className="TrackTags_list">
        { tags.map((tag, index) => { return <li key={index} className={getTrackListItemClassName(tag)}><span className="TrackTags_tag-text">{ tag }</span></li> }) }
      </ul>
    </div>
  );
};

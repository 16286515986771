import React, { useEffect } from 'react';
import useTitle from '../../hooks/useTitle';
import PageContainer from '../PageContainer';
import usePageView from '../../hooks/usePageView';
import { useDispatch, useSelector } from 'react-redux';
import { aboutFetchSoftware } from '../../redux/actions';
import { getAboutSoftware } from '../../redux/selectors';

import './AboutPage.scss';

function AboutPage() {
  useTitle('About');
  usePageView();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(aboutFetchSoftware());
  }, [dispatch]);

  const aboutSoftware = useSelector(getAboutSoftware);

  const makeSoftwareCategory = (softwareCategory) => {
    return (
      <div className="AboutPage_software-category-container" key={ softwareCategory.title }>
        <h2>{ softwareCategory.title }</h2>
        { makeSoftwareList(softwareCategory.software) }
      </div>
    );
  };

  const makeSoftwareList = (softwareList) => {
    return (
      <ul className="AboutPage_software-list">
        { softwareList.map((software, index) => { return (<li key={ index } className="AboutPage_software-list-item"><a href={ software.url } target="_blank" rel="noopener noreferrer">{ software.name }</a></li>)}) }
      </ul>
    );
  };

  return (
    <div className="AboutPage">
      <div className="container">
        <PageContainer>
          <div className="AboutPage_content-container">
            <div className="AboutPage_content">
              { aboutSoftware.map((softwareCategory) => { return makeSoftwareCategory(softwareCategory) }) }
            </div>
            <div className="AboutPage_content">
              <h2>Feeds</h2>
              <ul className="AboutPage_feeds-list">
                <li className="AboutPage_feeds-list-item">
                  <a href="/feed.rss">RSS</a>
                </li>
                <li className="AboutPage_feeds-list-item">
                  <a href="/feed.atom">ATOM</a>
                </li>
                <li className="AboutPage_feeds-list-item">
                  <a href="/feed.json">JSON</a>
                </li>
              </ul>
            </div>
          </div>
        </PageContainer>
      </div>
    </div>
  );
};

export default AboutPage;

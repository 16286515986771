import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsPlaying, getCurrentTime, getTrack, getVolume, getMuted } from '../redux/selectors';
import Slider, { sliderOrientations } from './Slider';
import Time from './Time';
import { playerStepBackward, playerStepForward, playerSeek, playerPause, playerPlay, playerSetVolume, audioEnd, audioTimeUpdate, playerMute, playerUnmute, playerFetchTracks } from '../redux/actions';
import makeTrackUrl from '../helpers/makeTrackUrl';

import './Player.scss';
import playIcon from '../img/controls/play-white.svg';
import pauseIcon from '../img/controls/pause-white.svg';
import backwardIcon from '../img/controls/step-backward-white.svg';
import forwardIcon from '../img/controls/step-forward-white.svg';

const getPlayPauseButton = (isPlaying, dispatch) => {
  let button;

  if (isPlaying) {
    button = <button className="Player_button" onClick={() => { dispatch(playerPause()) }}><img src={pauseIcon} alt="Pause Icon" /></button>;
  }
  else {
    button = <button className="Player_button" onClick={() => { dispatch(playerPlay()) }}><img src={playIcon} alt="Play Icon" /></button>;
  }

  return button;
};

const getVolumeIconClassName = (volume) => {
  const baseClassName = 'Player_volume-icon';

  if (volume === 0) {
    return `${baseClassName} ${baseClassName}--mute`;
  }
  else if (volume < 0.33) {
    return `${baseClassName} ${baseClassName}--low`;
  }
  else if (volume < 0.66) {
    return `${baseClassName} ${baseClassName}--medium`;
  }
  else {
    return `${baseClassName} ${baseClassName}--high`;
  }
};

const toggleMute = (muted, dispatch) => {
  if (muted) {
    dispatch(playerUnmute());
  }
  else {
    dispatch(playerMute());
  }
};

function Player() {
  const audioRef = useRef(null);

  const dispatch = useDispatch();

  /* Player Fetch Tracks API Call */
  useEffect(() => {
    dispatch(playerFetchTracks());
  }, [dispatch]);

  /* Player State */
  const isPlaying = useSelector(getIsPlaying);
  const currentTime = useSelector(getCurrentTime);
  const track = useSelector(getTrack);
  const volume = useSelector(getVolume);
  const muted = useSelector(getMuted);
  const computedVolume = muted ? 0 : volume;

  /* Player Track */
  useEffect(() => {
    if (track.name) {
      // audioRef.current.src = process.env.PUBLIC_URL + '/tracks/' + track.name + '.mp3';
      // audioRef.current.src = 'http://zyriskmusic.s3-website-us-west-1.amazonaws.com/' + track.name + '.mp3';
      audioRef.current.src = makeTrackUrl(track.name).stream;
    }
  }, [track]);

  /* Player Play / Pause Effect */
  /* Ensures that the audio element is either playing or paused according to what's in the store. */
  useEffect(() => {
    const audioElementIsEnded = audioRef.current.ended;

    if (isPlaying && !audioElementIsEnded) {
      audioRef.current.play();
    }
    else {
      audioRef.current.pause();
    }
  });

  /* Player Volume */
  useEffect(() => {
    audioRef.current.volume = computedVolume;
  }, [computedVolume]);

  /* Audio ELement timeupdate Callback */
  /* Updates the store when timeupdate fires. */
  const handleTimeUpdate = () => {
    dispatch(audioTimeUpdate(audioRef.current.currentTime));
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    }
  });

  /* Audio Element ended Callback */
  const handleEndedEvent = () => {
    dispatch(audioEnd());
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.addEventListener('ended', handleEndedEvent);

    return () => {
      audioElement.removeEventListener('ended', handleEndedEvent);
    }
  });

  /* Audio Element currentTime Store Synchronization */
  /* Ensures that the audio element's current time is in sync with the store. */
  useEffect(() => {
    const acceptableDeviance = 0.1; // One tenth of a second
    const elementTime = audioRef.current.currentTime;

    if (currentTime < elementTime + acceptableDeviance && currentTime > elementTime - acceptableDeviance) {
      // console.log("The store and the audio element are basically synchronized.");
    }
    else {
      // console.log("It's time to update the current time!!!");
      audioRef.current.currentTime = currentTime;
    }
  }, [currentTime]);

  return (
    <div className="Player">
      <div className="container">
        <div className="Player_items-container">
          <div className="Player_item Player_item--buttons-container">
            <button className="Player_button" onClick={() => { dispatch(playerStepBackward()) }}>
              <img src={backwardIcon} alt="Step Backward Icon" />
            </button>
            {getPlayPauseButton(isPlaying, dispatch)}
            <button className="Player_button" onClick={() => { dispatch(playerStepForward()) }}>
              <img src={forwardIcon} alt="Step Forward Icon" />
            </button>
          </div>
          
          <div className="Player_item Player_item--time-slider-container">
            <Slider current={currentTime} maximum={track.duration} updatePositionCB={ (newTime) => { dispatch(playerSeek(newTime)); } } orientation={sliderOrientations.HORIZONTAL}></Slider>
          </div>
          
          <div className="Player_item Player_item--time-container">
            <Time currentTime={currentTime} duration={track.duration ? track.duration : 0}></Time>
          </div>

          <div className="Player_item Player_item--volume-slider-container">
            <div className={ getVolumeIconClassName(computedVolume) } onClick={ () => { toggleMute(muted, dispatch) } }></div>
            <Slider current={computedVolume} maximum="1" updatePositionCB={ (newVolume) => { dispatch(playerSetVolume(newVolume)); } } orientation={sliderOrientations.VERTICAL}></Slider>
          </div>
        </div>
      </div>

      <audio className="Player_audio" controls ref={audioRef}></audio>
    </div>
  );
}

export default Player;

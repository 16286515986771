import React from 'react';

import './PageContainer.scss';

export const pageContainerLayouts = {
  NORMAL: 'NORMAL',
  TRACKS: 'TRACKS',
  TRACK: 'TRACK',
};

const getClassName = (layout) => {
  const baseClassName = 'PageContainer';

  if (layout === pageContainerLayouts.TRACKS) {
    return `${baseClassName} ${baseClassName}--tracks`;
  }

  if (layout === pageContainerLayouts.TRACK) {
    return `${baseClassName} ${baseClassName}--track`;
  }

  return baseClassName;
};

function PageContainer(props) {
  const { layout, children } = props;

  return (
    <div className={getClassName(layout)}>
      <div className="PageContainer_top">
        <div className="PageContainer_top_left-corner"></div>
        <div className="PageContainer_top_right-corner"></div>
      </div>

      <div className="PageContainer_content">
        <div className="PageContainer_content-left"></div>
        <div className="PageContainer_content-children">
          {children}
        </div>
        <div className="PageContainer_content-right"></div>
      </div>

      <div className="PageContainer_bottom">
        <div className="PageContainer_bottom_left-corner"></div>
        <div className="PageContainer_bottom_right-corner"></div>
      </div>
    </div>
  );
};

export default PageContainer;
